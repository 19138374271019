<template>
  <div>
    <v-container>
      <v-card-title 
        class="h1 justify-center"
      >
        {{ action }} Cadastrar E-mails
      </v-card-title>
      <validation-observer 
        ref="observer" 
        v-slot="{ invalid }">
        <form>
          <v-row
            class="input-emails"
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-textarea
                outlined
                no-resize
                name="input-7-4"
                label="E-mails"
                v-model="tef.email"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card-actions>
                <v-spacer />
                <buttons-cadastro
                  :disabled="invalid"
                  :value="action === 'Editar' ? 'Atualizar' : 'Cadastrar'"
                  @save="saveTef"
                  @close="$emit('tefAdded')"
                />
              </v-card-actions>
            </v-col>
          </v-row>

          
        </form>
      </validation-observer>
    </v-container> 
  </div>
</template>



<script>
import { mapActions, mapState } from 'vuex'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'

export default {
  name: 'CadastroEmailTef',
  components: { 
    ButtonsCadastro 
  },
  props: {
    tefData: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      tef: {},
      isEdit: false,
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
     ...mapState('tef', ['tefs']),
  },
  methods: {
    ...mapActions('tef', ['addTef', 'updateTef']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    async saveTef() {
      try {
        let tefDetails
        if (this.isEdit) {
          tefDetails = await this.updateTef({
            ...this.tef })
          this.$emit('tefAdded', tefDetails)
          this.successMessage('E-mails TEF atualizados com sucesso!')
        } else {
          this.tef.proprietario = this.empresaAtual.public_id
          tefDetails = await this.addTef({
            ...this.tef})
          this.$emit('tefAdded', tefDetails)
          this.successMessage('E-mails TEF cadastrado com sucesso!')
          this.tef = {}
        }
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
  
    atualizarDados() {
      Object.keys(this.tef).forEach((key) => {
        if (
          typeof this.tef[key] === 'object' &&
          this.tef[key] !== null
        ) {
          if (this.tef[key].public_id)
            this.tef[key] = this.tef[key].public_id
          else if (this.tef[key].id)
            this.tef[key] = this.tef[key].id
        }
      })
      this.isEdit = Object.keys(this.tef).length
    },

    tefObj() {
      if (this.tefs.length) {
        this.tef = this.tefs[0]
      }
    },
  },
  async created() {
    this.tefObj()
    this.atualizarDados()
  }
}
</script>

<style scoped>

</style>