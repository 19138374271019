<template>
    <v-container>
      <v-card-title 
        class="h1 justify-center"
      >
        {{ action }} Cadastrar TEF
      </v-card-title>
      <validation-observer 
        ref="observer" 
        v-slot="{ invalid }">
        <form>
          
          <v-row
            class="c-hour-email-input"
          >
            <v-col 
              cols="5"
              sm="5"
              md="5"
            >
              <v-text-field
                label="Duração da TEF"
                prepend-icon="mdi-timer-sync-outline"
                placeholder="horas"
                v-model="tef.hora"
              ></v-text-field>
            </v-col>

            <v-col 
              cols="2"
              sm="2"
              md="2"
            ></v-col>

            <v-col 
              cols="5"
              sm="5"
              md="5"
            >
              <v-checkbox 
                v-model="tef.envia_email"
              >
                <template v-slot:label>
                  <div>
                    Notificar e-mails cadastrados
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card-actions>
                <v-spacer />
                <buttons-cadastro
                  :disabled="invalid"
                  :value="action === 'Editar' ? 'Atualizar' : 'Cadastrar'"
                  @save="saveTef"
                  @close="$emit('tefAdded')"
                />
              </v-card-actions>
            </v-col>
          </v-row>
        </form>
      </validation-observer>
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'

export default {
  name: 'CadastroTef',
  components: { 
    ButtonsCadastro 
  },
  props: {
    tefItemData: {
      type: Object,
      required: false,
    },
    tefPublicId: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      tef: {
        envia_email: false
      },
      isEdit: false,
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },
  methods: {
    ...mapActions('tef', ['addTefItem', 'updateTefItems']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    async saveTef() {
      try {
        let tefDetails
        if (this.isEdit) {
          tefDetails = await this.updateTefItems({
            ...this.tef })
          this.$emit('tefAdded', tefDetails)
          this.tef = {}
          this.successMessage('TEF atualizado com sucesso!')
        } else {
          this.tef.tef = this.tefPublicId
          tefDetails = await this.addTefItem({
            ...this.tef})
          this.$emit('tefAdded', tefDetails)
          this.successMessage('TEF cadastrado com sucesso!')
          this.tef = {}
        }
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },

    atualizarDados() {
      Object.keys(this.tef).forEach((key) => {
        if (
          typeof this.tef[key] === 'object' &&
          this.tef[key] !== null
        ) {
          if (this.tef[key].public_id)
            this.tef[key] = this.tef[key].public_id
          else if (this.tef[key].id)
            this.tef[key] = this.tef[key].id
        }
      })
      this.isEdit = Object.keys(this.tef).length
    },
  },
  async created() {
    if (Object.keys(this.tefItemData || {} ).length) {
      this.tef = { ...this.tefItemData }

      this.atualizarDados()
    }
  }
}
</script>

<style scoped>

</style>