<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="TEF">
          <cadastro-email-tef
            v-if="showFormEmail"
            :tefData="tefData"
            @tefAdded="showFormEmail = false, tefData = {}"
          />
          <cadastro-tef 
            v-else-if="showForm"
            :tefItemData="tefItemData"
            :tefPublicId="tefPublicId"
            @tefAdded="showForm = false, tefItemData = {}"
          />
          <listar-tef
            v-else
            @sendData="getData"
            @showFormEmail="showFormEmail = true"
            @editTef="editTefItem"
            @editTefEmail="editTefEmail"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CadastroEmailTef from '@/components/patio/Tef/CadastroEmailTef.vue'
import CadastroTef from '@/components/patio/Tef/CadastroTef.vue'
import ListarTef from '../../components/patio/Tef/ListarTef.vue'


export default {
  components: { 
    CadastroTef, 
    ListarTef,
    CadastroEmailTef
    },
  data() {
    return {
      page: {
        title: 'Gerenciamento de Tef',
      },
      components: {
        CadastroTef,
        ListarTef
      },
      breadcrumbs: [
        {
          text: 'Pátios',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Tef',
          disabled: false,
        },
      ],
      showForm: false,
      showFormEmail: false,
      tefItemData: {},
      tefData: {},
      tefPublicId: ''
    }
  },
  
  methods: {
    editTefItem(tef) {
      this.tefItemData = tef
      this.showForm = true
    },
    editTefEmail(tefEmail) {
      this.tefData = tefEmail
      this.showFormEmail = true
    },
    getData (tefDataPublicId) {
      this.showForm = true
      this.tefPublicId = tefDataPublicId
    }
  },
}
</script>