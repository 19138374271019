<template>
  <div>
    <div class="d-loading">
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"> </v-progress-circular>
      </v-overlay>
    </div>

    <div class="d-filter-card-search mt-4">
      <v-card-title v-if="canList">
        Filtro
        <v-spacer> </v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
          @change="handleGetPeriferico"
        >
        </v-text-field>
      </v-card-title>

      <div class="d-table-list-tef">
        <v-data-table
          class="table-tef border"
          :headers="headers"
          :search="search"
          :items="tefsItems"
          item-key="public_id"
          sort-by="hora"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title> TEFs </v-toolbar-title>
              <v-divider class="mx-4" inset vertical> </v-divider>
              <v-icon
                v-if="canList"
                medium
                @click="() => handleGetPeriferico"
                title="Atualizar"
                >mdi-refresh</v-icon
              >
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="1000px"
                class="dialog-button"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="canCreate"
                    color="secondary"
                    dark
                    class="mb-2 mr-2"
                    v-on="on"
                    @click="$emit('showFormEmail', 'editTefEmail')"
                  >
                    E-mails
                  </v-btn>
                </template>
              </v-dialog>

              <v-dialog
                v-model="dialog"
                max-width="1000px"
                class="diaglog-nova-tef"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="canCreate"
                    color="primary"
                    class="mb-2"
                    v-on="on"
                    :disabled="tefs.length <= 0"
                    @click="$emit('sendData', tefs[0].public_id)"
                  >
                    Nova TEF
                  </v-btn>
                </template>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.hora`]="{ item }">
            {{ `${item.hora} horas` }}
          </template>
          <template v-slot:[`item.envia_email`]="{ item }">
            {{ item.envia_email == true ? 'SIM' : 'NÃO' }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="canUpdate"
              small
              class="mr-2"
              @click="$emit('editTef', item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon small v-if="canDelete" @click="prepareDeleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template #no-data v-if="!canList">
            <span class="red--text">
              Você não tem permissão para visualizar as informações desta tela
            </span>
          </template>
        </v-data-table>
      </div>
    </div>

    <v-dialog v-if="canDelete" v-model="dialogDeleteItem" max-width="500px">
      <v-card>
        <v-card-title>
          <v-card-text>
            <h4 class="transition-swing text-h5 mb--1 error--text">Atenção!</h4>
            <p class="mt-8">Tem certeza que deseja deletar essa TEF?</p>
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="
              dialogDeleteItem = false
              tefDelete = {}
            "
          >
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="deleteItem(tefDelete)">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <v-dialog v-if="canCreate" v-model="cantCreateTef" max-width="500px">
        <v-card>
          <v-card-title>
            <v-card-text>
              <h4 class="transition-swing text-h5 mb--1 error--text">
                Atenção!
              </h4>
              <p class="mt-8">
                Cadastre os e-mails antes de criar a primeira tef
              </p>
            </v-card-text>
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="cantCreateTef = false">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'ListarTef',
  data() {
    return {
      dialog: false,
      loading: false,
      cantCreateTef: false,
      search: '',
      headers: [
        { text: 'Período da TEF', value: 'hora' },
        { text: 'Enviar e-mail', value: 'envia_email' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        descricao: '',
      },
      defaultItem: {
        descricao: '',
      },
      dialogDeleteItem: false,
      tefDelete: {},
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('tef', ['tefs', 'tefsItems']),

    permission() {
      return Sequences.Veiculos.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },

    formTitle() {
      return this.editedIndex === -1 ? 'Novo' : 'Editar'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    if (this.canList) {
      await this.getTefs({ proprietario: this.empresaAtual.public_id })
      await this.getTefItems({ proprietario: this.empresaAtual.public_id })
    }
  },

  methods: {
    ...mapActions('tef', ['getTefs', 'getTefItems', 'deleteTefItems']),

    async handleGetPeriferico(search) {
      this.loading = true
      if (search.length >= 3) {
        await this.getPeriferico({ search })
      } else {
        alert('IP não encontrado')
      }
      this.loading = false
    },

    prepareDeleteItem(item) {
      this.dialogDeleteItem = true
      this.tefDelete = item
    },

    async deleteItem(item) {
      if (item.public_id) {
        await this.deleteTefItems(item)
      } else {
        alert('Houve um problema. Tente novamente!')
        return false
      }
      this.dialogDeleteItem = false
      this.tefDelete = {}
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
  },
}
</script>